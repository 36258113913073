import React from "react";
import { Alert, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import ImageWithDescription from "../../Components/ImageWithDescription";
const { Title } = Typography;

export const PicoLBE = () => {
  return (
    <div style={{ maxWidth: 900, background: "#ffffff0d", padding: 30 }}>
      <Title>Pico LBE Setup</Title>
      <Alert
        message="Alpha Release"
        description={
          <>
            Please note that Pico LBE support is currently in alpha. Some
            features may be unstable or subject to change.
          </>
        }
        type="warning"
        showIcon
        icon={<WarningOutlined />}
        style={{ marginBottom: 24 }}
      />
      <Alert
        message="Pico LBE/ Map Sharing"
        description={
          <>
            Create a map with one headset and share it with your other headsets.
            This means you no longer need to calibrate your headsets.
          </>
        }
        type="info"
        showIcon
        style={{ marginBottom: 24 }}
      />
      <section>
        <Title level={2}>Requirements</Title>
        <ol>
          <li>
            LBE Mode currently only supports{" "}
            <b>Pico 4 Ultra Enterprise headsets</b>. We may add support for Pico
            4 Enterprise headsets in the future.
          </li>
          <li>
            The Hero Zone background service must be installed and running on
            the headset. (reinstalling the games on your headsets will install
            and start the background service)
          </li>
        </ol>
      </section>

      <section>
        <Title level={2}>Map creation</Title>

        <ImageWithDescription
          width={800}
          src="/images/manual/picoLBE/createMap.jpg"
          description="Create Map"
        />
        <p>Choose a headset to create your map with.</p>
        <p>
          Before creating a map, make sure developer mode is activated on your
          headset. See the guide{" "}
          <a href="/quick-start?headset=Pico4#Activate%20Developer%20Mode">
            here
          </a>
          .
        </p>
        <p>
          To access the map creation page, go to Developer options - Business
          settings - LBE in your Pico headset.
        </p>
        <p>
          Click on Create map and follow the instructions on screen to complete
          the map creation.
        </p>
      </section>

      <section>
        <Title level={2}>Export Map</Title>
        <ImageWithDescription
          width={800}
          src="/images/manual/picoLBE/exportMap.jpg"
          description="Export Map"
        />
        Click on the export map button next to the headset that was used to
        create the map.{" "}
        <p>
          Ensure the Hero Zone app is running on the headset and is able to
          connect to the launcher.
        </p>
        <Alert
          message={
            <>
              <WarningOutlined /> The export will fail if the headset is in
              standby, make sure the screen is on
            </>
          }
          type="warning"
        />
      </section>
      <section>
        <Title level={2}>Import Map</Title>
        <p>
          If the LBE Mode setting and Map sharing settings are both enabled, the
          map should automatically be imported by all the headsets.
        </p>
        <p>
          Note that the Hero Zone Background Service needs to be running on the
          headset and be connected with the launcher. The easiest way to ensure
          this is to reinstall the games on the headset.
        </p>
        <p>
          When the map is successfully imported, the &quot;current Map&quot;
          field will be updated
        </p>
      </section>

      <section>
        <Title level={2}>FAQ</Title>

        <ul>
          <div>
            <Title level={3}>Using other software for map sharing</Title>
            <p>
              If you wish to use other software to implement map sharing, make
              sure the "Pico LBE Mode" option is enabled and "Map Sharing" is
              disabled.
            </p>
          </div>
          <div>
            <Title level={3}>Exiting LBE Mode</Title>
            <p>
              If the headset malfunctions or is taken to another location where
              it cannot regain positioning, you can quickly exit LBE mode by
              pressing the volume up and down buttons in a specific sequence.
              The sequence is ++--++-- (complete within 3 seconds).
            </p>
          </div>
        </ul>
      </section>
    </div>
  );
};
